require('jquery-ui/themes/base/all.css')
require('jquery-ui/ui/widgets/datepicker')

const monthNames = [
  'Январь',
  'Февраль',
  'Март',
  'Апрель',
  'Май',
  'Июнь',
  'Июль',
  'Август',
  'Сентябрь',
  'Октябрь',
  'Ноябрь',
  'Декабрь',
]

const monthNamesShort = [
  'Янв',
  'Фев',
  'Мар',
  'Апр',
  'Май',
  'Июн',
  'Июл',
  'Авг',
  'Сен',
  'Окт',
  'Ноя',
  'Дек',
]

const dayNames = [
  'воскресенье',
  'понедельник',
  'вторник',
  'среда',
  'четверг',
  'пятница',
  'суббота',
]

export function DatePickerDirective($timeout) {
  return {
    restrict: 'A',
    link,
    require: '?ngModel',
    scope: {
      ngModel: '=',
      onSelect: '&',
    },
  }

  function link(scope, el, attrs, ngModel) {
    if (!ngModel) {
      return
    }

    const $el = $(el[0])

    ngModel.$render = $render

    init()

    function init() {
      $el.datepicker({
        closeText: 'Закрыть',
        prevText: '&#x3c;Пред',
        nextText: 'След&#x3e;',
        currentText: 'Сегодня',
        monthNames,
        monthNamesShort,
        dayNames,
        dayNamesShort: ['вск', 'пнд', 'втр', 'срд', 'чтв', 'птн', 'сбт'],
        dayNamesMin: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
        weekHeader: 'Не',
        dateFormat: 'dd.mm.yy',
        firstDay: 1,
        isRTL: false,
        showMonthAfterYear: false,
        yearSuffix: '',
        changeMonth: true,
        changeYear: true,
        onSelect: function (dateStr) {
          const date = $.fn.dateutils.str_ddmmyyyy2date(dateStr, '.')

          ngModel.$setViewValue(date)

          $timeout(function () {
            onSelect(dateStr)
          })
        },
      })
    }

    function $render() {
      if (!ngModel.$viewValue) {
        $el.val(null)
      } else {
        const date = new Date(ngModel.$viewValue)
        $el.datepicker('setDate', date)
      }
    }

    function onSelect(dateStr) {
      if (!scope.onSelect) {
        return
      }

      const ts = !dateStr ? null : str2ts(dateStr)
      scope.onSelect({ date: ts })
    }
  }
}

// function ts2str (timestamp) {
//   const d = new Date(timestamp * 1000)
//   return d.getDate() + '.' + (d.getMonth() + 1) + '.' +
//     d.getFullYear()
// }

function str2ts(dateStr) {
  const parts = dateStr.split('.')
  if (parts.length !== 3) {
    throw new Error('Wrong date format')
  }
  const isoDateStr = parts.reverse().join('/')
  return new Date(isoDateStr).getTime() / 1000
}

// })();
