import { CRUDService, CRUDServiceAPI2 } from './crud-service.class'

export class ZaysService extends CRUDService {
  subject = 'zays'

  parse = (z) => {
    z.id = parseInt(z.id)
    z.dohod = parseFloat(z.dohod)
    z.zaid = parseInt(z.zaid)
    z.plid = parseInt(z.plid)
    z.poid = parseInt(z.poid)
    z.otid = parseInt(z.otid)

    if (z.__verbose__finanse) {
      z.__verbose__finanse.beznal_dohod = parseFloat(
        z.__verbose__finanse.beznal_dohod
      )
      z.__verbose__finanse.beznal_rashod = parseFloat(
        z.__verbose__finanse.beznal_rashod
      )
    }
    return z
  }

  toHtml(zay) {
    return zay.id + ' ' + zay.__verbose__plid
  }

  /**
   * Поисковые фильтры для заявок.
   * @param {string} text часть номера или плательщика заявки
   * */
  getSearchFilters(text) {
    return {
      _type: 'query',
      term: text,
      __verbose: 1,
      limit: 100,
    }
  }
}

/** Сервис для заявок на основе ApiV2 */
export class ZaysServiceApiV2 extends CRUDServiceAPI2 {
  subject = 'zays'

  parse = (p) => {
    p.nomdate = p.nomdate
      ? $.fn.dateutils.str_ddmmyyyy2date(p.nomdate, '.')
      : null
    p.dohod = p.dohod ? parseFloat(p.dohod) : 0.0
    p.rashod = p.rashod ? parseFloat(p.rashod) : 0.0
    return p
  }

  format = (o) => {
    o.nomdate = o.nomdate
      ? $.fn.dateutils.date2str_ddmmyyyy(o.nomdate, '.')
      : ''
    o.dohod = o.dohod ? o.dohod.toFixed(2) : '0.00'
    o.rashod = o.rashod ? o.rashod.toFixed(2) : '0.00'
    return o
  }

  /**
   * Получение сервиса для работы с ТТН заявки.
   * @param {number} zay Id заявки
   * @returns сервис для работы с ТТН заявки
   */
  getTtnService(zay) {
    return new ZayTTNServiceApiV2(this.$q, this.$http, this.FileSaver, zay)
  }
}

/** Сервис для ТТН на основе ApiV2 */
class ZayTTNServiceApiV2 extends CRUDServiceAPI2 {
  zay = null
  key_arg = 'nomer'

  get subject() {
    return `zays/${this.zay}/ttns`
  }

  constructor($q, $http, FileSaver, zay) {
    super($q, $http, FileSaver)
    this.zay = zay
  }
}
